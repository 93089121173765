<template>
	<div id="content">
		<Header v-bind:infos="infos" />
		<main>
			<!--Section présentation de moi-même-->
			<div class="container">
				<div class="presentationMoi row">
					<p class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Passionné par les technologies qui gravitent autour du web, je me
						suis spécialisé dans le développement web dont j'ai l'ambition d'en
						faire ma carrière professionnelle. Je suis une personne motivée,
						fiable, qui aspire toujours à en apprendre davantage et à s'investir
						pleinement dans son travail.
					</p>

					<img
						src="../assets/apropos/photo_antonin_winterstein.jpg"
						class="img-fluid offset-lg-4 offset-xl-4 col-sm-12 col-md-12 col-lg-4 col-xl-4"
						alt="Photo d'Antonin Winterstein"
						width="100"
						height="100"
					/>
					<p class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Grâce à mon parcours scolaire et les multiples projets
						professionnels auxquels j'ai participé, j'ai développé de nombreuses
						compétences dans le secteur de la création de site web. Ainsi je
						suis performant autant sur l’aspect programmation que l’aspect
						design, ergonomie sans oublier la gestion de projet où j’ai acquis
						une certaine rigueur ce qui me permet de mener à bien mes travaux.
					</p>
				</div>
			</div>

			<hr />

			<!--Section présentation du DUT MMI-->
			<div class="container">
				<div class="presentationMMI row">
					<p class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
						J'aime beaucoup apprendre et assimiler de nouvelles connaissances.
						J'ai eu la chance grâce au DUT Métiers du Multimédia et de
						l'Internet (MMI) de Montbéliard, de ne pas seulement acquérir des
						compétences dans le développement mais aussi dans les autres
						domaines essentiels à la construction de site web à savoir le design
						et la communication. Les projets et travaux appliqués réalisés lors
						de la formation m'ont d'autant plus donné l'occasion de renforcer
						mes compétences.
					</p>

					<img
						class="offset-lg-2 offset-xl-2 col-sm-12 col-md-12 col-lg-4 col-xl-4"
						src="../assets/apropos/logo_mmi.png"
						alt="Logo du DUT Métiers du Multimédia et de l'Internet (noir et blanc)"
					/>
				</div>
			</div>

			<hr />

			<!--Section présentation de la LP CIASIE-->
			<div class="container">
				<div class="presentationLPCIASIE row">
					<p class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
						Suite à mon DUT MMI, j'ai intégré la Licence Professionnelle
						Conception-Intégration d’Applications et Services Web pour
						l’Entreprise (CIASIE) à l'IUT Nancy-Charlemagne. Grâce à cette
						formation qui se concentre principalement sur l'aspect
						développement, je me suis d'autant plus amélioré sur cette branche
						et plus généralement sur les technologies du web. De plus, les
						nombreux projets de groupe m'ont familiarisé à la gestion de projets
						web à plusieurs.
					</p>

					<img
						class="offset-lg-2 offset-xl-2 col-sm-12 col-md-12 col-lg-4 col-xl-4"
						src="../assets/apropos/logo_iut_nancy_charlemagne.png"
						alt="Logo de l'IUT Nancy-Charlemagne"
					/>
				</div>
			</div>

			<!--Section me contacter-->
			<div class="container" style="margin-top: 8vh;">
				<div class="row text-center">
					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
						<p>
							Si vous avez un quelconque message ou des remarques à me faire
							passer, n'hésitez pas à me contacter, j'y répondrai au plus vite !
						</p>
					</div>
					<!--Bouton me contacter-->

					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
						<router-link to="contact" class="boutonMeContacter"
							>ME CONTACTER</router-link
						>
					</div>
				</div>

				<!--Section télécharger mon CV-->
				<div class="telechargerCVAPropos row text-center ">
					<p class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Vous pouvez par ailleurs télécharger mon CV ci-dessous si vous avez
						une quelconque offre !
					</p>
					<!--Bouton télécharger mon CV-->

					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
						<a
							href="https://antoninwinterstein.com/CV_Antonin_Winterstein.pdf"
							download="CV - Antonin Winterstein"
							class="boutonTelechargerCV"
							>CV</a
						>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
	metaInfo: function() {
		return {
			title: "Antonin Winterstein | À propos",
			meta: [
				{
					name: "description",
					content:
						"Vous trouverez ici différentes informations sur Antonin Winterstein, ses motivations et son parcours.",
				},
			],
		};
	},
	components: {
		Header,
	},
	data() {
		return {
			infos: {
				titrePage: "À PROPOS",
				urlImage: require("../assets/apropos/fond_apropos.jpg"),
			},
		};
	},
	mounted() {
		window.scrollTo(0, 0);
	},
};
</script>

<style lang="scss">
/*Page à propos*/
/*Présentation*/

.presentationMoi {
	margin-top: 3vh;

	p:nth-child(3) {
		margin-top: 1.5vh;
	}
}

.presentationMMI,
.presentationLPCIASIE {
	display: flex;

	img {
		align-self: center;
	}
}

.telechargerCVAPropos {
	margin-top: 5vh;
}
</style>
